import React from 'react'
import { ServicesHero, MainServices, Footer, Navbarwhite } from '../components'

const Services = () => {
  return (
    <>
      <Navbarwhite/>
      <ServicesHero/>
      <MainServices/>
      <Footer/>
    </>
  )
}

export default Services