import React from 'react'
import {BsFillCalendarWeekFill} from 'react-icons/bs'
import {MdAccessTimeFilled} from 'react-icons/md'
import {RiHeartsFill} from 'react-icons/ri'

const HowItWorks = () => {
  return (
    <div className='flex flex-col px-10 justify-center items-center sm:px-36 lg:py-20 '>
        <div className='py-2 w-full lg:text-center'>
            <h3 className='font-extrabold text-3xl'>How <span className='text-orange-500'>home service</span> works.</h3>
        </div>
        <div className='flex flex-col py-3 lg:flex-row w-full lg:w-[70vw]'>
            <div className='flex flex-col shadow-lg rounded p-10 lg:mx-5 mt-5 hover:scale-105 transition-all duration-200'>
                <BsFillCalendarWeekFill className='mb-5 text-orange-500 text-6xl '/>
                <h4 className='font-bold text-orange-500 font-jakarta text-2xl mb-3'>Book it.</h4>
                <p className='font-jakarta text-sm text-justify'>Select pickup date and time, for us to come and collect your laundry and dry cleaning(yep all of it). Prior to the first pick up we can arrage to get laundry bags for you to put your laundry and dry cleanig items in. We can also supply a laundry basket if you dont have one</p>
            </div>
            <div className='flex flex-col bg-orange-500 shadow-lg rounded p-10 lg:mx-5 mt-5 hover:scale-105 transition-all duration-200'>
                <MdAccessTimeFilled className='mb-5 text-white text-6xl '/>
                <h4 className='text-white font-bold font-jakarta text-xl mb-3'>Track it.</h4>
                <p className='text-sm text-white font-jakarta text-justify'>Next, we live up to our name and get to washing, drying, folding and dry cleaning. When we’ve picked up your laudry, we can let you know when to expect delivery. If your availability changes just contact us via chat, email or phone and we’ll reorganise to suit you. You’re welcome.</p>
            </div>
            <div className='flex flex-col shadow-lg rounded p-10 lg:mx-5 mt-5 hover:scale-105 transition-all duration-200'>
                <RiHeartsFill className='mb-5 text-orange-500 text-6xl '/>
                <h4 className='font-bold text-orange-500 font-jakarta text-xl mb-3'>Love it.</h4>
                <p className='font-jakarta text-sm text-justify'>Lastly, we deliver your items back to you in a convinient mobile wardrobe or reusable bags. All pressed items are hung and dry cleaning is stored in a recycled bags. Maximum convinience, minimum waste. You dont even have to put your clothes away - just wheel your wardrobe inside and thats that.</p>
            </div>
        </div>

    </div>
  )
}

export default HowItWorks