import React from 'react'
import { topWearPricing, curtainsPricing, bottomWearPricing, pressingPricing, sareesDressesPricing, leatherPricing, accessoriesPricing, loadwashPricing, householdPricing } from '../data'

const PriceList = () => {
  return (
    <div className='flex flex-col px-8 lg:px-28 justify-center flex-wrap mt-20'>
        <div className='mb-10'>
            <h3 className='font-extrabold text-fuchsia-600 text-[36px] '>Pricing</h3>
        </div>
        <div className='flex flex-col lg:flex-row justify-between w-full gap-20'>
            {/* pricing column 1 */}
            <div className='flex flex-col w-full'>
                <div className='mb-10'>
                <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>TOP WEAR</h3>
                    {topWearPricing.map((pricing, index) =>(
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='font-medium text-[16px]'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
                <div className=''>
                    <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>CURTAINS & CARPETS</h3>
                    {curtainsPricing.map((pricing, index) => (
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='text-[16px] font-medium'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* Pricing column 2 */}
            <div className='flex flex-col w-full'>
                <div className='mb-10'>
                    <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>SAREES, DRESSES & GOWNS</h3>
                    {sareesDressesPricing.map((pricing, index) => (
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='text-[16px] font-medium'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
                <div className='mb-10'>
                    <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>LEATHER & EXCLUSIVES</h3>
                    {leatherPricing.map((pricing, index) => (
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='text-[16px] font-medium'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
                <div className=''>
                    <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>ACCESSORIES</h3>
                    {accessoriesPricing.map((pricing, index) => (
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='text-[16px] font-medium'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* Pricing column 3 */}
            <div className='flex flex-col w-full'>
                <div className='mb-10'>
                    <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>HOUSEHOLD & LINEN</h3>
                    {householdPricing.map((pricing, index) => (
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='text-[16px] font-medium'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
                <div className='mb-10'>
                    <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>BOTTOM WEAR</h3>
                    {bottomWearPricing.map((pricing, index) => (
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='text-[16px] font-medium'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
                <div className='mb-10'>
                    <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>PRESSING</h3>
                    {pressingPricing.map((pricing, index) => (
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='text-[16px] font-medium'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
                <div className='mb-10'>
                    <h3 className='text-fuchsia-600 font-bold mb-5 text-[16px]'>LOADWASH</h3>
                    {loadwashPricing.map((pricing, index) => (
                        <div key={pricing.id} className='flex justify-between items-center border-0 border-b-[1px] hover:scale-105 transition-all duration-200 border-b-fuchsia-200 mb-3'>
                            <p className='text-[16px] font-medium'>{pricing.name}</p>
                            <p className='text-[16px]'>{pricing.price}</p>
                        </div>
                    ))}
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default PriceList