import React from 'react'
import {logo} from '../assets'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {HiMail} from 'react-icons/hi'
import {RiInstagramFill} from 'react-icons/ri'
import {FaFacebookSquare, FaPaperPlane} from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='bg-gray-100 flex flex-col'>
      <div className='p-10 sm:px-36 items-center'>
        {/* Footer line 1 */}
        <div className='flex flex-col lg:flex-row lg:justify-between'>
          {/* logo and description */}
          <div className='flex justify-between items-center lg:w-1/3'>
            <img src={logo} alt="wash logo" className='w-20'/>
            <p className='w-3/5 text-[14px] font-jakarta text-justify'>We’re here when you need us, whether it’s for your regular laundry service, same day dry clean or laundry service, a one-off dry clean or simply a stain you can’t get rid of. </p>
          </div>
          {/* Quicklinks */}
          <div className='flex flex-col my-5'>
            <h3 className='text-orange-500 text-[16px] font-bold font-jakarta mb-2'>Quick Links</h3>
            <Link to='/' className='mb-1 font-jakarta text-[14px] hover:text-orange-500 transition-all duration-200'>Home</Link>
            <Link to='/services' className='mb-1 font-jakarta text-[14px] hover:text-orange-500 transition-all duration-200'>Services</Link>
            <Link to='/pricing' className='mb-1 font-jakarta text-[14px] hover:text-orange-500 transition-all duration-200'>Pricing</Link>
            <Link to='/home-services' className='mb-1 font-jakarta text-[14px] hover:text-orange-500 transition-all duration-200'>Home Services</Link>
            <Link to='/contact-us' className='mb-1 font-jakarta text-[14px] hover:text-orange-500 transition-all duration-200'>Contact us</Link>
          </div>
          {/* Contact details */}
          <div className=''>
          <h3 className='text-orange-500 text-[16px] font-bold font-jakarta my-5'>Contact Us</h3>
            <div className='flex items-end gap-5 mb-2'>
              <BsFillTelephoneFill className='text-[36px] bg-orange-500 text-white p-2 rounded-full' />
              <p className='font-jakarta text-[14px]'>+254715880842</p>
            </div>
            <div className='flex items-end gap-5 mb-2'>
              <FaPaperPlane className='text-[36px] text-white bg-orange-500 p-2 rounded-full'/>
              <p className='font-jakarta text-[14px]'>info@wash.co.ke</p>
            </div>
            <div className='flex items-end gap-5 mb-2'>
              <HiMail className='text-[36px] text-white bg-orange-500 p-2 rounded-full'/>
              <p className='font-jakarta text-[14px]'>P.O. Box 244144 - 00100</p>
          </div>
          </div>
        </div>
        {/* Horizontal dividing line */}
        <div className='border-t-orange-500 border-t-[1px] my-8'></div>
        {/* Footer line 2 */}
        <div className='flex flex-col lg:flex-row lg:justify-around items-center mt-10'>
          <div>
            <h3 className='font-windsong font-bold text-orange-500 text-[30px] leading-6 mb-5'>You do the living, <span className='font-black block text-black' >we do the laundry!</span></h3>
          </div>
          <div>
            <p className='font-jakarta mb-5'>All rights Reserved © 2023</p>
          </div>
          <div className='flex gap-3'>
            <a href="https://www.instagram.com/wash_drycleaners/">
            <RiInstagramFill className='text-[42px] text-white p-2 bg-orange-500 rounded-full'/>
            </a>
            <a href="https://www.facebook.com/">
            <FaFacebookSquare className='text-[42px] text-white p-2 bg-orange-500 rounded-full'/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer