import { laundryCleaningHeroData } from '../data'

const LaundryCleaningHero = () => {
  return (
    <div className='flex flex-col justify-center items-center px-8 flex-wrap sm:px-28 p-5 mb-5 lg:mb-20'>
        
        
        {laundryCleaningHeroData.map((data, index) => (
            <div key={data.id} className='flex lg:justify-between flex-col-reverse py-5 lg:flex-row w-full items-center'>
                <div className='mb-5 lg:w-2/5'>
                    <h1 className='text-3xl lg:text-5xl my-8 lg:mb-8 font-extrabold text-fuchsia-600 leading-relaxed'>Laundry
                    <span className=' text-black'> Cleaning.</span></h1>
                    {data.content.map((p) => (
                        <p key={p.id} className='font-jakarta text-justify text-[14px]'>{p.paragraph}</p>
                    ))}
                </div>
                <div className='mb-5'>
                    <img src={data.url} className="rounded-lg shadow-xl object-cover lg:min-h-[70vh] min-h-[40vh] lg:max-w-[40vw] transition-all duration-500 hover:scale-105" alt="wash-services" />
                </div>
            </div>
        ))}
    </div>
  )
}

export default LaundryCleaningHero
