
import 'antd/dist/reset.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {Services, Pricing, Home, Contact, HomeService, LaundryCleaning, DryCleaning, CommercialCleaning} from './pages'

function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/home-service' element={<HomeService/>} />
        <Route path='/pricing' element={<Pricing/>} />
        <Route path='/laundry-cleaning' element={<LaundryCleaning/>} />
        <Route path='/dry-cleaning' element={<DryCleaning/>} />
        <Route path='/commercial-cleaning' element={<CommercialCleaning/>} />
        <Route path='/contact-us' element={<Contact/>} />
        <Route path='/*' element={<Home/>} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
