import React from 'react'
import { HowitWorkswnf, Navbarwhite, PriceList, PricingHero, WashKilaKitu, WashnFold, Footer } from '../components'

const Pricing = () => {
  return (
    <>
      <Navbarwhite />
      <PricingHero/>
      {/* <WashnFold/> */}
      {/* <HowitWorkswnf/> */}
      {/* <WashKilaKitu/> */}
      <PriceList/>
      <Footer/>
    </>

  )
}

export default Pricing