import { contacts, location } from '../data'
import Iframe from 'react-iframe'
import {RiInstagramFill} from 'react-icons/ri'
import {FaFacebookSquare, FaPaperPlane} from 'react-icons/fa'
import { useFormik } from "formik"
import * as Yup from 'yup'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactHero = () => {

    const formik = useFormik({
        initialValues:{
            fullname:"",
            email:"",
            message:"",
        },
        // Form validation
        validationSchema: Yup.object({
            fullname: Yup.string()
                .max(50, 'Name must be 50 characters or less.')
                .required("Name is required"),
            email: Yup.string()
                .email('Invalid email address')
                .max(50, 'Email must be 50 characters or less.')
                .required("Email is required"),
            message: Yup.string()
                .max(300, 'Message must be 100 characters or less.')
                .required("Message is required"),
        }),

    })

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_9p64a6m', 'template_cs84k2j', form.current, 'fUzE6fKQoGifbOqRX')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
        });
        e.target.reset()
    }

    return (
    <div className=''>
        <div className='flex flex-col lg:flex-row items-center lg:justify-between '>
            <div className='w-full lg:w-1/2'>
                {/*  Map*/}
                <Iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1578.3730485479057!2d36.70676255938085!3d-1.3235113676865704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1b332bb4415d%3A0xc4aaed7139d82b55!2sWash%20Dry%20Cleaners!5e0!3m2!1sen!2ske!4v1670245387000!5m2!1sen!2ske"
                    allowFullcreen
                    loading="lazy"
                    title="Wash location"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="rounded-lg shadow-sm w-full h-[88vh] mb-10"
                    style={{border:0}}
                />
            </div>
            <div className='w-full lg:w-1/2 flex flex-col px-8 sm:px-28 justify-center'>
                <div>
                    <h1 className='text-[36px] lg:text-[54px] font-extrabold mb-10'>Say <span className='text-fuchsia-600'> hello,</span></h1>
                </div>
                <div>
                    <div className='flex flex-col justify-center lg:flex-row lg:justify-between lg:items-center'>
                        <div className='mb-5'>
                            <h2 className='text-fuchsia-600 font-jakarta font-bold text-[24px]'>Our Stores</h2>
                            {location.map((data) => (
                                <div key={data.id}>
                                    <p className='font-jakarta text-[16px]'>{data.title}</p>
                                </div>
                            ))}
                        </div>
                        <div className='mb-5'>
                            <h2 className='text-fuchsia-600 font-jakarta font-bold text-[24px]'>Contacts</h2>
                            {contacts.map((data) => (
                                <div key={data.id}>
                                    <p className='font-jakarta text-[16px]'>{data.content}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Contact form */}
                    <div className='w-full'>
                        <h2 className='text-[30px] font-jakarta font-bold'>Have a <span className='text-fuchsia-600 mb-10'>question?</span></h2>
                        <form ref={form} onSubmit={sendEmail}>
                                <div className='flex flex-col lg:flex-row lg:justify-between mb-5'>
                                    <input type="text" 
                                    name='fullname' placeholder={formik.touched.fullname && formik.errors.fullname ? formik.errors.fullname : "Full Name"} className='p-2 border-0 border-b-2 border-b-fuchsia-600 active:outline-0 active:ring-0 
                                    w-4/5 lg:w-2/5 mb-3 text-[14px]' required value={formik.values.fullname} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <input type="email" required name='email' placeholder={formik.touched.email && formik.errors.email ? formik.errors.email : "johndoe@domain.com"} className='p-2 border-0 border-b-2 border-b-fuchsia-600 active:outline-0 active:ring-0 w-4/5 lg:w-2/5 mb-3 text-[14px]' value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                </div>
                                <textarea name="message" id="message" cols="60" rows="10" placeholder={formik.touched.message && formik.errors.message ? formik.errors.message : "Your message"} className='p-2 border-0 border-b-2 border-b-fuchsia-600 active:outline-0 active:ring-0 w-4/5 lg:w-full mb-5 text-[14px]' required value={formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} ></textarea>
                                <button type="submit" className='flex items-center justify-center bg-fuchsia-600 p-3 w-1/3 text-white text-center rounded-lg hover:scale-105 transition-transform duration-200'><FaPaperPlane className='mr-3' />Send</button>
                        </form>
                    </div>
                    <div className='text-white text-[48px] flex mt-5 gap-3'>
                    <a href="https://www.instagram.com/wash_drycleaners/">
                        <RiInstagramFill className='bg-fuchsia-600 rounded-full p-2' />
                    </a>
                    <a href="https://www.facebook.com/">
                        <FaFacebookSquare className='bg-fuchsia-600 rounded-full p-2'  />
                    </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactHero