import { ContactHero, Faq, Navbarwhite, Footer } from '../components'

const Contact = () => {
  return (
    <>
      <Navbarwhite/>
      <ContactHero/>
      <Faq/>
      <Footer/>
    </>
  )
}

export default Contact