import {Link} from 'react-router-dom'

const MainServices = () => {
  return (
    <div className='flex flex-col justify-center items-center flex-wrap lg:mt-20 p-8 sm:px-28'>
        <h2 className='text-[36px] font-extrabold mb-5'>Our <span className=' text-orange-500'>Services.</span></h2>

        <div className='flex flex-col lg:flex-row lg:justify-around justify-center items-center mb-12'>
            <Link to='/laundry-cleaning' className='w-full shadow-md rounded-xl hover:shadow-lg my-5 transition-all duration-200 hover:translate-y-3 m-4 pb-5'>
                <img className='rounded-lg' src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910399/wash-laundry-services-hero_u9form.jpg" alt="wash-laundry" />
                <p className='mt-3 px-5 text-orange-500 font-jakarta font-semibold text-xl'>Laundry cleaning.</p>
                <p className='line-clamp-2 text-justify font-jakarta px-5 text-[14px]'>At Wash we take pride in the laundering of your personal fine linen and cloth wear. Every care is taken at each step of our process to ensure your laundry is beautifully finished prior to being returned to you. All items are individually inspected and then washed with the gentlest detergents before being finished to our exacting standards.
                </p>
            </Link>
            <Link to='/dry-cleaning' className='w-full shadow-md rounded-xl hover:shadow-lg my-5 transition-all duration-200 hover:translate-y-3 m-4 pb-5'>  
                <img className='rounded-lg' src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910405/wash-suits_xypxrz.jpg" alt="wash-dry-clean" />
                <p className='mt-3 px-5 text-orange-500 font-jakarta font-semibold text-xl'>Dry cleaning</p>
                <p className='line-clamp-2 text-justify font-jakarta px-5 text-[14px]'>The dry cleaning procedures at Wash uses the finest technology and the purest of solvents to deliver impeccable results for everything from suits to designer couture.</p>
            </Link>
            <Link to='/commercial-cleaning' className='w-full shadow-md rounded-xl hover:shadow-lg my-5 transition-all duration-200 hover:translate-y-3 m-4 pb-5'>
                <img className='rounded-lg' src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910400/wash-n-fold_aabodr.jpg" alt="wash-laundry" />
                <p className='mt-3 px-5 text-orange-500 font-jakarta font-semibold text-xl'>Commercial cleaning</p>
                <p className='line-clamp-2 text-justify font-jakarta px-5 text-[14px]'>Large corporations have determined that there is a financial benefit to outsourcing back office work because it saves money. Allowing us to do your laundry is cost effective and will allow you and your employees focus on your core business.</p>
            </Link>
        </div>
    </div>
  )
}

export default MainServices