import { delivery } from '../assets'

const HomeServiceHero = () => {
  return (
    <div className='flex flex-col flex-wrap justify-center items-center px-8 pt-8 lg:px-0 lg:pt-0'>
        <div className='lg:relative w-full lg:h-[88vh]'>
            <img src={delivery} alt="wash delivery" className='lg:absolute lg:right-0 lg:bottom-0 lg:w-3/4 lg:h-7/8 mb-10 lg:mb-0'/>
            <div className='lg:absolute lg:w-1/3 lg:bottom-[30%] lg:left-28'>
                <h1 className='text-black text-[36px] lg:text-[54px] font-extrabold'>Home <span className='text-orange-500'>pick-up <span className='block'> and delivery </span></span> service.</h1>
                <p className='text-justify font-jakarta text-[14px] lg:text-[18px]'>Get your dry cleaning and laundry picked up from your home, cleaned by our professional and then delivered back to you. Simply fill out the form below to schedule a home pick-up in Nairobi,
                </p>
                <div className='my-10 flex flex-col lg:flex-row '>
                    <a href='#schedule' className='border-2 border-orange-500 p-3 rounded-lg text-orange-500 font-jakarta text-[14px] hover:border-0 hover:bg-orange-600 hover:text-white hover:p-4 transition-all duration-300 w-52 text-center'>Schedule a home service</a>
                </div>
            </div>

        </div>
    </div>
  )
}

export default HomeServiceHero