import { CommercialCleaningHero, Footer, Navbarwhite, CommercialClients } from '../components'

const CommercialCleaning = () => {
  return (
    <>
        <Navbarwhite/>
        <CommercialCleaningHero/>
        <CommercialClients/>
        <Footer/>
    </>
  )
}

export default CommercialCleaning