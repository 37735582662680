import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { commercialClients } from '../data';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const CommercialClients = () => {
  return (
    <div className="flex flex-col p-4">
    <h2 className="font-extrabold text-[24px] lg:text-[36px] text-center">
      Our <span className="text-orange-600">Commercial</span> clients
    </h2>
    <div className="mb-1">
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={30} // Reduce spacing for smaller screens
        slidesPerView={1} // Default to 1 slide per view
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        breakpoints={{
          // Adjust slides per view and space for different screen sizes
          640: { slidesPerView: 2, spaceBetween: 20 }, // Small screens
          768: { slidesPerView: 3, spaceBetween: 30 }, // Medium screens
          1024: { slidesPerView: 4, spaceBetween: 40 }, // Large screens
        }}
        className="my-10"
      >
        {commercialClients.map((data) => (
          <SwiperSlide
            key={data.id}
            className="p-6 rounded-lg flex flex-col justify-center items-center"
          >
            <div className="text-[54px] text-center text-orange-400 p-5">
              {data.icon}
            </div>
            <p className="text-center font-jakarta text-[14px]">{data.title}</p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
  
  )
}

export default CommercialClients