// import logo from '../wash logo.svg'
import {Hero, Advantages, Services, WashKilaKituHome, CommercialClients,WashnFoldHome,Schedule, Location, Faq,Footer } from '../components'

const Home = () => {
  return (
    <>
      <Hero/>
      <Advantages />
      {/* <Services /> */}
      {/* <WashKilaKituHome/> */}
      {/* <WashnFoldHome/> */}
      <Schedule/>
      <Location/>
      <CommercialClients/>      {/* <Location/> */}
      <Faq/>
      <Footer/>
    </>
  )
}

export default Home