import {BsFillCalendarWeekFill} from 'react-icons/bs'
import {MdAccessTimeFilled} from 'react-icons/md'
import {RiHeartsFill} from 'react-icons/ri'
// import {FaHotel, FaChurch, FaAirbnb} from 'react-icons/fa'
// import {VscOrganization} from 'react-icons/vsc'
// import {CgGym} from 'react-icons/cg'
// import { GiNurseFemale } from 'react-icons/gi'
// Import logos as React components or images
import HuaweiLogo from '../assets/huawei.png';
import EldonVillasLogo from '../assets/villas.png'; // Replace with actual path
import MonarchHotelLogo from '../assets/monarch.png'; // Replace with actual path
import DunhillApartmentsLogo from '../assets/dunhill.png'; // Replace with actual path
import LvctHealthLogo from '../assets/lvct.png'; // Replace with actual path
import MatteosLogo from '../assets/matteos.png'; // Replace with actual path


export const navLinks = [
    {
        id:'',
        title:'Home',
    },
    {
        id: 'services',
        title: 'Services',
    },
    {
        id:'pricing',
        title: 'Pricing',
    }, 
    {
        id: 'home-service',
        title: 'Home Pickup',
    },
    // {
    //     id:'contact-us',
    //     title:'Contact us'
    // },
]

export const advantagesParagraphs = [
    {
        id: 'p-1',
        content:"We’re here when you need us, whether it’s for your regular laundry service, same day dry clean or laundry service, a one-off dry clean or simply a stain you can’t get rid of. We want to make your life easier, cleaner and smart.",
    },
    {
        id:'p-2',
        content:"For as long as people need their laundry cleaned, we’ll keep building our footprint and providing more easily located stores and a level of service that only a nationwide brand can produce."
    }
]

export const faqs = [
    {
        id:'faq-1',
        question:'What is the minimum order for home delivery services?',
        answer:'The delivery is free of additional charge – however the order must be at least Kes 1500. We appreciate that you might not know in advance precisely how much your cleaning charges will be however if you are comfortable with the minimum charge, we are happy to collect.',
    },
    {
        id:'faq-2',
        question:'What are the delivery and collection times?',
        answer:'We are happy to deliver and collect on the day of you choosing 7 days a week. Monday through Saturdays we deliver from 8am to 8pm. Sundays we deliver between 10am and 6pm. We can try an accommodate a "delivery window" for you but unfortunately we unable to provide specific times as routes are dynamic and traffic conditions vary. We will always try and call you prior to coming to your house. If we are unable to reach you, we will arrange for delivery or collection the following day.',
    },
    {
        id:'faq-3',
        question:'Do you provide detailed invoices?',
        answer: 'After your garments are collected by our driver we will send you an invoice by text and email –normally within 12 hours of collection. Your invoice will provide you with a detailed accounting of your articles as well as the total amount due.'
    },
    {
        id:'faq-4',
        question:'What are the prices for your services?',
        answer:'Many of the items are cleaned at the base price. ',
    },
    {
        id:'faq-5',
        question:'How much time does it take to deliver?',
        answer:'Our standard timing for home delivery is 2 to 3 days. If however you need your garments returned more quickly we can try and accommodate this. Our customer service team is happy to discuss this with you and see how we can best be of service.',
    },
    {
        id:'faq-6',
        question:'Do you have on demand or monthly plans?',
        answer:'Many of our clients simply want us to show up the same day every week. Other clients would prefer that we come on an as needed basis. Just let us know what suits you we have monthly plans to suit you like WASH Kila Kitu Plan and WASH and Fold Plan',
    },
    {
        id:'faq-7',
        question:'How do I book for a collection of my laundry?',
        answer:'It’s perhaps easiest to do online at www.wash.co.ke We will respond within the same hour/day. Alternatively, you can call our offices at +254 715 880 842',
    },
    
]

export const location = [
    {
        id:1,
        title:'Crossroads Mall, Karen',
    },
    {
        id:2,
        title:'Remax Mall, Argwings Khodek Rd'
    },
    {
        id:3,
        title:'105 Mami Dada, James Gichuru Rd.'
    },
]

export const days = [
    {
        id:1,
        title: 'Monday',
    },
    {
        id:2,
        title:'Tuesday',
    },
    {
        id:3,
        title:'Wednesday'
    },
    {
        id:4,
        title:'Thursday'
    },
    {
        id:5,
        title:'Friday'
    },
    {
        id:6,
        title:'Saturday'
    },
    {
        id:7,
        title:'Sunday'
    }
]

export const times = [
        {
            id:1,
            title: '8AM - 7PM',
        },
        {
            id:2,
            title: '8AM - 7PM',
        },
        {
            id:3,
            title: '8AM - 7PM',
        },
        {
            id:4,
            title: '8AM - 7PM',
        },
        {
            id:5,
            title: '8AM - 7PM',
        },
        {
            id:6,
            title: '8AM - 7PM',
        },
        {
            id:7,
            title: '8AM - 7PM',
        }
]

export const howItWorks = [
    {
        id:1,
        icon:<BsFillCalendarWeekFill/>,
        title:'Book it',
        content: 'Select pickup date and time, for us to come and collect your laundry and dry cleaning(yep all of it). Prior to the first pick up we can arrage to get laundry bags for you to put your laundry and dry cleanig items in. We can also supply a laundry basket if you dont have one',
    },
    {
        id:2,
        icon:<MdAccessTimeFilled/>,
        title:'Track it.',
        content: "Next, we live up to our name and get to washing, drying, folding and dry cleaning. When we’ve picked up your laudry, we can let you know when to expect delivery. If your availability changes just contact us via chat, email or phone and we’ll reorganise to suit you. You’re welcome.",
    },
    {
        id:3,
        icon:<RiHeartsFill/>,
        title:'Love it',
        content:"Lastly, we deliver your items back to you in a convinient mobile wardrobe or reusable bags. All pressed items are hung and dry cleaning is stored in a recycled bags. Maximum convinience, minimum waste. You dont even have to put your clothes away - just wheel your wardrobe inside and thats that.",
    },
]



export const mainServicesHeroData = [
    {
        id:1,
        title: 'Wash Dry Cleaner services',
        content:'Wash Dry Cleaners offers a professional dry cleaning and laundry service focused on high quality clothing, meticulous fabric care, and discreet and attentive service. Combining the finest traditions of craftsmanship with the most advanced, environmentally friendly techniques in fabric care.',
        url:'https://res.cloudinary.com/do3idyzar/image/upload/v1672910398/wash-laundry-satisfaction_h6hr6s.jpg'
    }
]

export const laundryCleaningHeroData = [
    {
        id:1,
        title: 'Laundry cleaning',
        content: [
            {
                id:1,
                paragraph:"At Wash Dry cleaners we take pride in the laundering of your personal fine linen and cloth wear. Every care is taken at each step of our process to ensure your laundry is beautifully finished prior to being returned to you. All items are individually inspected and then washed with the gentlest detergents before being finished to our exacting standards. "
            },
            {
                id:2,
                paragraph:"Our impeccable laundry service includes clothing, bed linens, lingerie, table linens, towels and more. During laundry cleaning non-toxic detergents and conditioners are used to lift dirt out of the garment and revitalize the fabric."
            },
            {
                id:3,
                paragraph:"The garments are agitated in the computerized laundry cleaning machine just enough to extract the dirt and grime, but not enough to alter the structure, size or color. The garments are then transferred to a high-tech drying unit that senses humidity as many as 400 times per minute. During drying, the conditioners that were added earlier are heat-activated to soften and freshen the garment. A specialized finishing equipment is used to return the garment to its original form."
            }
        ],
        url:'https://res.cloudinary.com/do3idyzar/image/upload/v1672910399/wash-laundry-services-hero_u9form.jpg'
    }
]

export const laundryCleaningServices = [
    {
        id:1,
        title:'Curtains',
        content:[
            {
                id:1,
                paragraph:"The window treatments in any room truly complete the style, look and feel of your home. Not only can we care for the fabric of vintage curtains that have been in your family home for many years but also more recent purchases too. "
            },
            {
                id:2,
                paragraph:"Wash cleans and restores all types of curtains to ensure that textures are rejuvenated, colours remain beautiful and vibrant. To give you complete peace of mind, Wash offers the finest cleaning of lined and interlined curtains."
            },
            {
                id:3,
                paragraph:"Wash cleans and restores all types of curtains to ensure that textures are rejuvenated, colours remain beautiful and vibrant. To give you complete peace of mind, Wash offers the finest cleaning of lined and interlined curtains. We can also take care of curtain accessories such as tie-backs, swags and pelmets as well as a range of blinds including Austrian, Festoon and Roman Blinds. Whether your curtains are made from linen or chenille, cotton or voile, Wash can ensure that they will be handled with the utmost care by our experienced professionals."
            },
            {
                id:4,
                paragraph:"We understand that transporting curtains to one of our shops can be difficult, so for bulky items many of our customers choose to make use of our complimentary collection and delivery service."
            },
        ],
        url:"https://res.cloudinary.com/do3idyzar/image/upload/v1672910392/wash-curtains_tv4jq3.webp"
    },
    {
        id:2,
        title:'Rugs and Carpets',
        content:[
            {
                id:1,
                paragraph:"Whether it’s a Persian Rug or an Axminster Carpet, the experts at Wash Dry Cleaners will provide the highest quality tailored restoration service. Prized rugs and carpets are an integral part of your home and restoring and cleaning them in the way they deserve is a specialist endeavour."
            },
            {
                id:2,
                paragraph:"We understand that transporting rugs to from your home to our stores can be difficult, so for bulky items many of our customers choose to make use of our complimentary collection and delivery service."
            },
        ],
        url:"https://res.cloudinary.com/do3idyzar/image/upload/v1672910426/wash-carpets-2_cqe6ki.jpg"
    },
    {
        id:3,
        title:'Suede and Leather',
        content:[
            {
                id:1,
                paragraph:"Cleaning of any item that is tailored from leather, suede or sheepskin demands specialist knowledge. Wash Dry Cleaners carefully assesses each garment before selecting the most appropriate procedures which may employ both traditional methods and more advanced technology."
            },
            {
                id:2,
                paragraph:"Garments are processed either in GreenEarth solvent or wet cleaned and then refinished with either specialist oils or waxes to restore the natural feel and look. If appropriate garments may also be re-tinted with dye to improve colour or change the appearance of the item."
            },
        ],
        url:"https://res.cloudinary.com/do3idyzar/image/upload/v1672910401/wash-leather-jackets_vhq521.jpg"
    },
    {
        id:4,
        title:'Toys',
        content:[
            {
                id:1,
                paragraph:"Soft toys become worn more often through affection than through neglect. In either case, treasured old friends that may have great sentimental value can usually be repaired and rejuvenated with our special hand-cleaning methods. We aim to lavish favourite toys with the love and attention they deserve."
            },
        ],
        url:"https://res.cloudinary.com/do3idyzar/image/upload/v1672910426/wash-toys-2_zb9wbz.jpg"
    },
    {
        id:5,
        title:'Wedding gown cleaning, preservation and storage',
        content:[
            {
                id:1,
                paragraph:"The time and effort you invest in your bridal garments doesn’t begin and end with your wedding day. Wedding dress alterations may be needed prior to ensure your dress is cut to perfection. Following wedding dress cleaning, you may wish to ready your gown for storage with wedding dress preservation."
            },
            {
                id:2,
                paragraph:"At Wash Dry cleaners, we take great pride in providing the highest degree of attention such special garments deserve. Over the years we have built unparalleled experience in wedding dress cleaning and wedding dress preservation."
            },
            {
                id:3,
                paragraph:"We examine the fabrics and characteristics of all gowns prior to cleaning to pinpoint optimal treatment methods. Ornamental features such as needlework, beads and embellishments are protected or removed beforehand and reinstated afterwards with painstaking precision.Opt for our wedding dress preservation service and have your gown delicately wrapped in acid-free tissue and arranged in an elegant, purpose-designed box."
            },
        ],
        url:"https://res.cloudinary.com/do3idyzar/image/upload/v1672912834/wash-wedding-gown_atwffd.jpg"
    },
]

export const dryCleaningHeroData = [
    {
        id:1,
        title: 'Dry cleaning',
        content:[
            {
                id:1,
                paragraph:"The dry cleaning procedures at Wash uses the finest technology and the purest of solvents to deliver impeccable results for everything from suits to designer couture."
            },
            {
                id:2,
                paragraph:"We specialize in first class stain removal, attention to detail and hand finishing of all garments. Any minor repairs such as loose hems, open seams and loose buttons will also be attended to as part of the service at an extra cost."
            },
            {
                id:3,
                paragraph:"Garments are individually protected and packaged with quality hangers. We use the most advanced dry cleaning solutions in fabric after care. A variety of solvents and cleaning techniques are used depending on the garments construction. ornamentation and fabric as well the degree of soiling and types of stains."
            },
        ],
        url:"https://res.cloudinary.com/do3idyzar/image/upload/v1672913249/wash-dry-cleaning_mpriqg.jpg",
    }
]

export const commercialCleaningHeroData = [
    {
        id:1,
        title: 'Commercial cleaning.',
        content:[
            {
                id:1,
                paragraph:"Large corporations have determined that there is a financial benefit to outsourcing back office work because it saves money. Allowing us to do your laundry is cost effective and will allow you and your employees focus on your core business."
            },
            {
                id:2,
                paragraph:"We offer smart solutions to meet your commercial and personal laundry needs. Our pick-up and delivery laundry service is fast, convenient, and saves you time and money. Our machines are all different shapes and sizes to fit the needs of our clients, commercially or individually. We have specialized spotting machines to provide the best value added service out there!"
            }
        ],
        url:"https://res.cloudinary.com/do3idyzar/image/upload/v1672910427/wash-commercial-laundry_o66vqo.jpg",
    }
]



export const commercialClients = [
    {
      id: 1,
      icon: (
        <img
          src={HuaweiLogo}
          alt="Huawei Logo"
          className="logo-class"
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
    {
      id: 2,
      icon: (
        <img
          src={EldonVillasLogo}
          alt="Eldon Villas Logo"
          className="logo-class"
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
    {
      id: 3,
      icon: (
        <img
          src={MonarchHotelLogo}
          alt="The Monarch Hotel Logo"
          className="logo-class"
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
    {
      id: 4,
      icon: (
        <img
          src={DunhillApartmentsLogo}
          alt="Dunhill Service Apartments Logo"
          className="logo-class"
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
    {
      id: 5,
      icon: (
        <img
          src={LvctHealthLogo}
          alt="LVCT Health Logo"
          className="logo-class"
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
    {
      id: 6,
      icon: (
        <img
          src={MatteosLogo}
          alt="Matteos Logo"
          className="logo-class"
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
  ];

export const washFoldPricing = [
    {
        id:1,
        title:"Essential plan (7kgs)",
        pricing: [
            {
                id:1,
                title:'1 monthly pickup',
                price: 'Kshs 1,950',
            },
            {
                id:2,
                title:'2 monthly pickups',
                price: 'Kshs 3,775',
            },            {
                id:3,
                title:'3 monthly pickups',
                price: 'Kshs 5,250',
            },
        ],
        save:'6% savings'
    },
    {
        id:2,
        title:"Premier plan (15kgs)",
        pricing: [
            {
                id:1,
                title:'1 monthly pickup',
                price: 'Kshs 4,175',
            },
            {
                id:2,
                title:'2 monthly pickups',
                price: 'Kshs 7,250',
            },            {
                id:3,
                title:'3 monthly pickups',
                price: 'Kshs 9,675',
            },
        ],
        save:'13% savings'
    },
    {
        id:3,
        title:"Advantage plan (20kgs)",
        pricing: [
            {
                id:1,
                title:'1 monthly pickup',
                price: 'Kshs 5,565',
            },
            {
                id:2,
                title:'2 monthly pickups',
                price: 'Kshs 8,680',
            },            {
                id:3,
                title:'3 monthly pickups',
                price: 'Kshs 12,300',
            },
        ],
        save:'18% savings'
    },
]

export const washKilaKituPricing = [
    {
        id:1,
        title:"Light plan",
        features:[
            {
                id:1,
                name:"1 Bag",
            },
            {
                id:2,
                name:"Two bi-monthly pickups",
            },
            {
                id:3,
                name:"Free pick-up and delivery",
            },
            {
                id:4,
                name:"Save 21% on regular pricing",
            },
            {
                id:5,
                name:"Ideal for 1-2 member households",
            },
        ],
        price:'Kshs 7,800'
    },
    {
        id:2,
        title:"Essential plan",
        features:[
            {
                id:1,
                name:"2 Bags",
            },
            {
                id:2,
                name:"Two bi-monthly pickups",
            },
            {
                id:3,
                name:"Free pick-up and delivery",
            },
            {
                id:4,
                name:"Save 27% on regular pricing",
            },
            {
                id:5,
                name:"Ideal for 2-4 member households",
            },
        ],
        price:'Kshs 13,900'
    },
    {
        id:3,
        title:"Premier plan",
        features:[
            {
                id:1,
                name:"3 Bag",
            },
            {
                id:2,
                name:"Two bi-monthly pickups",
            },
            {
                id:3,
                name:"Free pick-up and delivery",
            },
            {
                id:4,
                name:"Save 36% on regular pricing",
            },
            {
                id:5,
                name:"Ideal for 3-5 member households",
            },
            {
                id:6,
                name:"Includes carpets curtains, leather and suede jackets",
            },
        ],
        price:'Kshs 18,400'
    },
    {
        id:4,
        title:"Advantage plan",
        features:[
            {
                id:1,
                name:"4 Bags",
            },
            {
                id:2,
                name:"Two bi-monthly pickups",
            },
            {
                id:3,
                name:"Free pick-up and delivery",
            },
            {
                id:4,
                name:"Save 48% on regular pricing",
            },
            {
                id:5,
                name:"Ideal for 7 member households",
            },
            {
                id:6,
                name:"Includes carpets curtains, leather and suede jackets",
            },
        ],
        price:'Kshs 24,900'
    },
]

export const contacts = [
    {
        id:1,
        content:'+254715880842'
    },
    {
        id:2,
        content: 'info@wash.co.ke'
    },
    {
        id:3,
        content:'P.O Box 244144 - 00100'
    },
]

export const topWearPricing = [
    {
        id:1,
        name:'SHIRT',
        price:300
    },
    {
        id:2,
        name:'T-SHIRT',
        price:300
    },
    {
        id:3,
        name:'VEST',
        price:150
    },
    {
        id:4,
        name:' SILK SHIRT',
        price:400
    },
    {
        id:5,
        name:'BLAZER / JACKETS',
        price:500
    },
    {
        id:6,
        name:'BRASSIER',
        price:300
    },
    {
        id:7,
        name:'OVERCOAT',
        price:500
    },
    {
        id:8,
        name:'SILK BLOUSE',
        price:400
    },
    {
        id:9,
        name:'SUIT',
        price:800
    },
    {
        id:10,
        name:'KIDS SUITS',
        price:450
    },
    {
        id:11,
        name:'WHITE / CREAM SUITS',
        price:1000
    },
    {
        id:12,
        name:'2 PIECE SUITS',
        price:800
    },
    {
        id:13,
        name:'3 PIECE SUITS',
        price:1000
    },
    {
        id:14,
        name:'KAUNDA SUITS',
        price:800
    },
    {
        id:15,
        name:'SAFARI SUITS',
        price:800
    },
    {
        id:16,
        name:'SCHOOL BLAZER',
        price:500
    },
    {
        id:17,
        name:'BABY JACKET',
        price:250
    },
    {
        id:18,
        name:'APRON',
        price:400
    },
    {
        id:19,
        name:'AFRICAN ATTIRE',
        price:1000
    },
    {
        id:20,
        name:'KURTA SUIT',
        price:800
    },
    {
        id:21,
        name:'PUNJABI SUIT',
        price:1000
    },
    {
        id:22,
        name:'1 PIECE KANZU',
        price:800
    },
    {
        id:23,
        name:'2 PIECE KANZU',
        price:1200
    },
    {
        id:24,
        name:'SWEATER',
        price:400
    },
    {
        id:25,
        name:'WAIST COAT',
        price:450
    },
    {
        id:26,
        name:'COAT',
        price:500
    },
    {
        id:27,
        name:'WHITE / CREAM COAT',
        price:600
    },
    {
        id:28,
        name:'KNIT WEAR',
        price:500
    },
    {
        id:29,
        name:'DUST COAT',
        price:400
    },
    {
        id:30,
        name:'TRENCH COAT',
        price:500
    },
]

export const curtainsPricing = [
    {
        id:1,
        name:'CURTAINS PER KG',
        price:600
    },
    {
        id:2,
        name:'SHEER/NET PER PIECE',
        price:300
    },
    {
        id:3,
        name:'CARPET PER SQFT',
        price:90
    },
]

export const bottomWearPricing = [
    {
        id:1,
        name:'SKIRT',
        price:400
    },
    {
        id:2,
        name:'PLEATED SKIRT',
        price:600
    },
    {
        id:3,
        name:'PANTIES / BOXER',
        price:100
    },
    {
        id:4,
        name:'SHORTS',
        price:450
    },
    {
        id:5,
        name:'TROUSER',
        price:400
    },
]

export const pressingPricing = [
    {
        id:1,
        name:'SHIRT',
        price:150
    },
    {
        id:2,
        name:'TROUSER',
        price:200
    },
]

export const sareesDressesPricing = [
    {
        id:1,
        name:'NIGHT DRESS',
        price:500
    },
    {
        id:2,
        name:'BABY DRESS',
        price:300
    },
    {
        id:3,
        name:'PLEATED DRESS',
        price:800
    },
    {
        id:4,
        name:'EVENING WEAR',
        price:1000
    },
    {
        id:5,
        name:'WEDDING GOWN',
        price:5000
    },
    {
        id:6,
        name:'BRIDE MAID DRESS',
        price:1500
    },
    {
        id:7,
        name:'DRESS',
        price:600
    },
    {
        id:8,
        name:'DRESS SUIT',
        price:1000
    },
    {
        id:9,
        name:'INDIAN SAREE SMALL',
        price:700
    },
    {
        id:10,
        name:'INDIAN SAREE MEDIUM',
        price:1100
    },
    {
        id:11,
        name:'INDIAN SAREE LARGE',
        price:2000
    },
    {
        id:12,
        name:'GRADUATION GOWN & CAP',
        price:1000
    },
    {
        id:13,
        name:'PRIEST GOWNS',
        price:700
    },
    {
        id:14,
        name:'BUI BUI',
        price:400
    },
]

export const leatherPricing = [
    {
        id:1,
        name:'LEATHER JACKET',
        price:3000
    },
    {
        id:2,
        name:'SUEDE JACKET',
        price:3000
    },
    {
        id:3,
        name:'SLEEPING BAG',
        price:1300
    },
    {
        id:4,
        name:'SHEEP SKIN',
        price:1000
    },
    {
        id:5,
        name:'HANDBAG SPA',
        price:800
    },
    {
        id:6,
        name:'CAR SEAT COVERS',
        price:1000
    },
]

export const accessoriesPricing = [
    {
        id:1,
        name:'TIE',
        price:100
    },
    {
        id:2,
        name:'SCARF',
        price:350
    },
    {
        id:3,
        name:'SHAWL',
        price:450
    },
    {
        id:4,
        name:'BASEBALL CAPS',
        price:400
    },
    {
        id:5,
        name:'HATS',
        price:400
    },
    {
        id:6,
        name:'HANDKERCHIEF',
        price:100
    },
    {
        id:7,
        name:'SOCKS',
        price:100
    },
    {
        id:8,
        name:'GLOVES',
        price:200
    },
    {
        id:9,
        name:'BELT',
        price:200
    },
]

export const loadwashPricing = [
    {
        id:1,
        name:'LAUNDRETTE 1KG  PER LOAD',
        price:350
    },
]

export const householdPricing = [
    {
        id:1,
        name:'TABLECLOTH',
        price:300
    },
    {
        id:2,
        name:'TOWEL',
        price:400
    },
    {
        id:3,
        name:'TEDDY BEAR SMALL',
        price:500
    },
    {
        id:4,
        name:'TEDDY BEAR MEDIUM',
        price:700
    },
    {
        id:5,
        name:'TEDDY BEAR LARGE',
        price:1500
    },
    {
        id:6,
        name:'PILLOW',
        price:400
    },
    {
        id:7,
        name:'HAND TOWEL',
        price:300
    },
    {
        id:8,
        name:'FACE TOWEL',
        price:200
    },
    {
        id:9,
        name:'BEDSHEETS (PER PC)',
        price:450
    },
    {
        id:10,
        name:'KING / QUEEN BLANKET',
        price:1400
    },
    {
        id:11,
        name:'BLANKET SMALL',
        price:1200
    },
    {
        id:12,
        name:'BED / MATRESS COVER',
        price:600
    },
    {
        id:13,
        name:'PILLOW CASE',
        price:250
    },
    {
        id:14,
        name:'CUSHION COVERS',
        price:400
    },
    {
        id:15,
        name:'DUVET KING SIZE',
        price:1900
    },
    {
        id:16,
        name:'DUVET QUEEN SIZE',
        price:1500
    },
    {
        id:17,
        name:'DUVET COVERS',
        price:600
    },
    {
        id:18,
        name:'DUVET SMALL BED COVER',
        price:1000
    },
    {
        id:19,
        name:'BED COVER KING / QUEEN',
        price:600
    },
    {
        id:20,
        name:'BED COVER SMALL',
        price:400
    },
    {
        id:21,
        name:'BATHROBES',
        price:500
    },
    {
        id:22,
        name:'TOWEL SMALL',
        price:250
    },
]